import WorkDesktop from "./WorkDesktop"

const Work = () =>{
    return(
        <div>            
            <WorkDesktop/>
        </div>
    )
}

export default Work