import Navigation from "./Navigation";

const NavBar = () => {
    return(
        <div >
            <Navigation/>
        </div>
    )
}

export default NavBar;